import React, {useEffect} from 'react';
import AppBar from "../../components/AppBar";
import CssBaseline from '@mui/material/CssBaseline';
import Box from "@mui/material/Box/Box";
import Container from "@mui/material/Container/Container";
import { useTheme } from '@mui/styles';
import {Route, Redirect, withRouter, useLocation, Switch, useParams} from "react-router-dom";
import {ACTION_TYPE, context} from "../../State";
import {useTranslation} from "react-i18next";
import Api from "../../helpers/service";
import {log, tryLocalStorageSetItem} from "../../helpers/utils";
import SvgComponent from '../../img/4-rounded-squares-component';
import Menu from "../../components/VerticalMenu";
import g2Icon from '../../img/g2icon.svg'
import { ReviewButton } from '../../components/CustomUI';
import ContractsPage from './ContractsPage';


const menuItems = [
    {
        title: "Integrations",
        path: "/integrations",
        icon: <SvgComponent size={'24px'} />,
        layout: <ContractsPage/>
    }
];

function getMenuItemByPath(path) {
    let item = menuItems.find((item) => {
        return item.path === path
    });
    if (item === undefined) { return menuItems[0]}
    return item;
}

function isValidPath(path) {
    return menuItems.find((item) => {
        return item.path === path
    }) !== undefined;
}

let lastSessionRefresh = Date.now();

// Broadcast that session is refreshed to other browser tabs
tryLocalStorageSetItem("lastSessionRefresh", lastSessionRefresh);

const MainLayout = (props) => {

    const {state, dispatch} = React.useContext(context);
    const { email } = props;
    const theme = useTheme();
    const location = useLocation();
    const [selectedLocationTitle, setSelectedLocationTitle] = React.useState(getMenuItemByPath(location.pathname).title);
    const { t, i18n } = useTranslation();
    const api = new Api();
   

    useEffect(
        () => {
            setSelectedLocationTitle(getMenuItemByPath(location.pathname).title)
        },
        [location.pathname]
    );



    function onLogout() {
        api.logout().then(function (res) {
            log(res);
            dispatch({type: ACTION_TYPE.PARTNER_USER , payload:false});
            dispatch({
                type: ACTION_TYPE.USER_DATA,
                payload: {
                    email: ""
                }
            });
            window.location.reload(false);
        }).catch(function (error) {
            log(error);
        });
    }

    function openReview() {
        let url = "/ideepartners/g2/review";
        window.open(url, '_blank');
    }


    return (
        <React.Fragment>
            <CssBaseline />
            <Container maxWidth={false} disableGutters sx={{paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2)}}>
                <AppBar title={selectedLocationTitle} email={email} onLogout={onLogout}/>
                <div style={{display:'flex', padding: '3%'}}>
                <Box sx={{ paddingTop:'7vh', width: '20%', minWidth: '200px'}} >
                    <Menu items={menuItems}/>
                    <ReviewButton onClick={openReview}> 
                                <img src={g2Icon} style={{
                                    display: 'flex',
                                    width: '24px',
                                    height: '24px',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }} />
                                <span style={{
                                    textAlign: 'center',
                                    fontFamily: 'Source Sans Pro',
                                    fontSize: '17px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: '24px'
                                }}>{t('review_btn')}</span>
                     </ReviewButton>
                </Box>
                <Box sx={{ paddingTop:'1%', width:'87%', marginTop: '6dvh'}} >

                    <Switch>
                        { !isValidPath(location.pathname) && <Redirect to={menuItems[0].path}/>}
                        <Route path="/:path">
                            <MenuItemLayout/>
                        </Route>
                    </Switch>
                </Box>
                </div>
            </Container>
        </React.Fragment>
    );
};

function MenuItemLayout() {
    let { path } = useParams();
    return (
        getMenuItemByPath(`/${path}`).layout
    );
}


export default withRouter(MainLayout);
