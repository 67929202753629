import React, {useEffect} from 'react';
import BasicLayout from "../BasicLayout";
import ColouredHeaderCard from "../../components/ColouredHeaderCard";
import {withRouter} from "react-router-dom";
import {styled} from "@mui/material";
import Api from "../../helpers/service";
import Box from "@mui/material/Box/Box";
import {useTranslation} from "react-i18next";
import { Button } from '../../components/CustomUI';
import Typography from '@mui/material/Typography';
import {log} from "../../helpers/utils";


const QrCode = styled('img')({
    imageRendering: 'pixelated',
    width:'55%',
    paddingTop: '15%',
    marginLeft: '0',
    marginRight: '0'
});

function LoginPage(props) {

    const {t} = useTranslation();
    const {history} = props;
    const api = new Api();
    const [qrCodeData, setQrCodeData] = React.useState('');
    let refreshIntervalId;
    let refreshQrInterval = 300; // in seconds

    useEffect(
        () => {
            getQr();
            refreshIntervalId = setInterval(() => getQr(), refreshQrInterval * 1000);
        },
        []
    );

    useEffect( () => () => clearInterval(refreshIntervalId), [] ); // on unmount

    function getQr() {
        api.getQrCode().then(function (res) {
            setQrCodeData(res)
        }).catch(function (error) {
            log(error);
        })
    }


    function gotoSetup() {
        history.push("/setup")
    }
    const content = (
        <Box sx={{ display: 'grid', textAlign: 'center' }}>
            <ColouredHeaderCard title={t('login.login_to_ip')} isEmpty={qrCodeData === "" || qrCodeData === "data:image/png;base64,undefined"} content={
                <div>
                    <Typography variant="h4" sx={{
                        fontSize: 20,
                        '@media (min-width:1366px) and (max-width:1919px)': {
                            fontSize: '20px'
                        },
                        '@media (min-width:1920px)': {
                            fontSize: '20px'
                        },
                        fontWeight: 700, paddingTop: '5%', paddingLeft: '5%', paddingRight: '5%'
                    }}>
                        {t('login.scan_qr')}
                    </Typography>
                    <QrCode src={qrCodeData} style={{ display: (qrCodeData === "" || qrCodeData === "data:image/png;base64,undefined") ? "none" : "inline-block" }} />
                </div>} />
            <Typography variant="h4" sx={{
                fontSize: 18,
                '@media (min-width:1366px) and (max-width:1919px)': {
                    fontSize: '18px'
                },
                '@media (min-width:1920px)': {
                    fontSize: '18px'
                },
                fontWeight: 600, paddingTop: '5%', paddingLeft: '5%', paddingRight: '5%'
            }}>
                {t('login.dont_have_account')}
            </Typography>
            <Button
                sx={{ fontSize: '17px', 
                marginTop: '10px', 
                marginBottom: '10px', 
                marginLeft: '634px', 
                marginRight: '634px', 
                width: '276px', 
                height: '48px', 
                paddingLeft: '19px', 
                paddingRight: '19px', 
                paddingTop: '8px', 
                paddingBottom: '8px', 
                '&:hover': { backgroundColor: '#4557CC', transform: 'none' } }}
                onClick={event => gotoSetup()}>
                {t('login.try_for_free')}
            </Button>
        </Box>
    );

    return (
       <BasicLayout content={content} hasFooter={false}/>
    );
}

export default withRouter(LoginPage);