import React, { useEffect } from "react";
import BasicTabs from "../../components/BasicTabs";
import ContractTable from "../../components/ContractTable";
import Api from "../../helpers/service";
import { context } from "../../State";
import {useTranslation} from "react-i18next";
import { CircularProgress } from "@mui/material";

export default function ContractsPage(props) {

    var mspRowsArray = [];
    var resellersRowsArray = [];
    let api = new Api();
    const {t} = useTranslation();
    const state = React.useContext(context);
    const [contracts, setContracts] = React.useState(null);
    const [mspRows, setMspRows] = React.useState([]);
    const [resellersRows, setResellersRows] = React.useState([]);
    const [loaded, setLoaded] = React.useState(false);
    var responseContracts;
    

    useEffect(async () => {
            let response = await api.getContracts(responseContracts);
            setContracts(response);
    }, [state.isPartnerUser]);

    useEffect(() => {
        console.log(contracts);
        if (contracts !== null && contracts !== undefined) {
            for (var index in contracts) {
                let contract = contracts[index];
                if (contract.contractDTO.licenseLimit === 1000000) {
                    mspRowsArray.push(contract);
                } else {
                    resellersRowsArray.push(contract);
                }
            }
            setResellersRows(resellersRowsArray);
            setMspRows(mspRowsArray);
            setLoaded(true);
        }
    }, [contracts]);

    
    let mspContractTable = <ContractTable rows={mspRows} />
    let resellersContractTable = <ContractTable rows={resellersRows} />

    return (
        <>
            <span style={{
                fontFamily: 'Source Sans Pro',
                fontSize: 'calc(36px + 1.5dvh)',
                fontStyle: 'normal',
                fontWeight: '700',
                lineHeight: '48px', /* 133.333% */
                letterSpacing: '0.36px'
            }}> {t('integrations')} </span>

            {!loaded &&
                <CircularProgress color="primary" sx={{ position: 'absolute', top: '50%', left: '50%' }} />
            }
            {loaded && 
             <BasicTabs mspContent={mspContractTable} resellersContent={resellersContractTable}/>
             }
            
        </>
    )
}