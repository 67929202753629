
export function log(msg) {
    if (process.env.REACT_APP_ENV === 'DEBUG' || process.env.NODE_ENV === 'development') {
        console.log(msg);
    }
}

export function tryLocalStorageSetItem(key, value) {
    try {
        localStorage.setItem(key, value);
    } catch (e) {
        // Local storage might not be available
    }
}

export function tryLocalStorageGetItem(key, defaultValue) {
    try {
        let val = localStorage.getItem(key);
        if (val != null) {
            return val;
        }
    } catch (e) {
        // Local storage might not be available
    }
    return defaultValue;
}

export function formatDate(longTime) {
    const date = new Date(longTime);
    return new Intl.DateTimeFormat('default', { year: 'numeric', month: 'short', day: '2-digit'}).format(date)
}
