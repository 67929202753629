import {
    Collapse,
    TableRow
} from "@mui/material";
import React from "react";
import IntegrationTable from "./IntegrationTable";
import logoPlaceholder from "../img/logo-placeholder.svg"
import { 
    BodyTableCell,
 } from "./CustomUI";
import { formatDate } from "../helpers/utils";

export default function CollapsableRow(props) {
    const { row, index } = props;
    const [open, setOpen] = React.useState(false);

    let integrations = row.integrations;
    let numberOfIntegrations = integrations.length;
    let licenseLimit = (row.contractDTO.licenseLimit < 1000000 ? row.contractDTO.licenseLimit : '∞');

    return (
        <>
            <TableRow key={row.contractId} sx={{cursor: 'pointer'}} onClick={() => setOpen(!open)}>
                <BodyTableCell sx={{textAlign: 'center'}}> {index + 1} </BodyTableCell>
                <BodyTableCell> {row.adminEmail} </BodyTableCell>
                <BodyTableCell> {row.contractDTO.contractID} </BodyTableCell>
                <BodyTableCell> {formatDate(row.contractDTO.startDate)} </BodyTableCell>
                <BodyTableCell> {formatDate(row.contractDTO.endDate)} </BodyTableCell>
                <BodyTableCell> {row.contractDTO.licenseType} </BodyTableCell>
                <BodyTableCell> {row.contractDTO.licensedUsers + "/" + licenseLimit} </BodyTableCell>
                <BodyTableCell> {row.contractDTO.enrolledUsers + "/" + licenseLimit} </BodyTableCell>
                <BodyTableCell> {numberOfIntegrations + "/" + row.contractDTO.integrationsLimit} </BodyTableCell>
            </TableRow>
            <TableRow sx={{ visibility: open ? 'visible' : 'collapse' }}>
                <BodyTableCell colSpan={10}>
                    <Collapse in={open} timeout="auto" unmountOnExit={true} style={{margin: '-20px'}}>
                        <IntegrationTable contractIndex={index + 1} rows={integrations} />
                    </Collapse>
                </BodyTableCell>
            </TableRow>
        </>
    )
}