import React from 'react';
import {Route, Redirect, withRouter} from "react-router-dom";

const AuthRoute = props => {
    const {isPartnerUser, type} = props;
    if (type === "guest" && isPartnerUser) return <Redirect to="/" />;
    else if (type === "private" && !isPartnerUser) return <Redirect to="/login" />;
    return <Route exact {...props}/>
};

export default withRouter(AuthRoute)