import * as React from 'react';
import { useEffect } from 'react';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import { styled } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import Fade from '@mui/material/Fade';
import { useTheme } from "@mui/styles";
import { publicUrl } from "../App";
import { context } from "../State";
import { InverseButton } from './CustomUI';
import LogoutComp from '../img/logout-icon-component';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const StyledAppBar = styled(MuiAppBar)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    boxShadow: 'none',
}));

const Logo = styled('img')({
    width: '150%',
    minWidth: '145px',
    marginTop: '40%',
    marginLeft: '40%'
});

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    alignItems: 'flex-start',
    height: 80,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    // Override media queries injected by theme.mixins.toolbar
    '@media all': {
        height: '8vh',
        marginTop: '4vh'
    },
}));


function ElevationScroll(props) {
    const { children } = props;
    return React.cloneElement(children, {
        elevation: useScrollTrigger() ? 4 : 0,
    });
}


function FadeInOnScroll(props) {
    const trigger = useScrollTrigger();
    const theme = useTheme();
    return (
        <Fade in={trigger}>
            <Typography
                variant="h2"
                noWrap
                component="div"
                sx={{fontSize: 'calc(36px + 1.5dvh)', flexGrow: 1, alignSelf: 'center', marginLeft: '13vw' }}
            >
                {props.title}
            </Typography>
        </Fade>
    );
}

export default function AppBar(props) {
    const { onLogout, email } = props;
    const { state, dispatch } = React.useContext(context);
    const { t } = useTranslation();
    const location = useLocation();


    return (
        <React.Fragment>
            <CssBaseline />
            <ElevationScroll {...props}>
                <StyledAppBar sx={{ backgroundColor: location.pathname === "/setup" ? '#F7F7F7' : '#FFFFFF'}}>
                    <StyledToolbar disableGutters>
                        <Box sx={{ width: '10dvh', textAlign: 'center', alignSelf: 'center' }} >
                            <Logo src={publicUrl + "/company-logo.svg"} alt="Logo" />
                        </Box>
                        <FadeInOnScroll {...props} />
                        
                        { !onLogout && (location.pathname=== "/login") &&
                            <div style={{display: 'flex', paddingTop: '4vh', paddingRight: '3vw'}}>
                                <InverseButton sx={{borderRadius:10}} onClick={()=>{ window.open("https://getidee.com/admin-support", "_blank");}}>
                                    <Typography variant="h3" sx={{
                                                                    fontWeight: 400,
                                                                    '@media (max-width:1365px)': {
                                                                        fontSize: 18,
                                                                    },
                                                                    '@media (min-width:1366px) and (max-width:1919px)': {
                                                                        fontSize: 18,
                                                                    },
                                                                    '@media (min-width:1920px) and (max-width:2559px)': {
                                                                        fontSize: 18,
                                                                    },
                                                                    '@media (min-width:2560px)': {
                                                                        fontSize: 24,
                                                                    },
                                                                    }}> 
                                    {t('login.get_help')}
                                    </Typography>
                                </InverseButton>
                            </div>
                        }

                        {onLogout &&
                            <div style={{display: 'flex', paddingRight: '2vw', marginLeft: 'calc(20px + 30%)'}}>
                                <Typography variant="h5" style={{
                                    fontFamily: 'Source Sans Pro',
                                    fontSize: 'calc(10px + 1.2vh)',
                                    fontWeight: 400,
                                    lineHeight: '24px',
                                    letterSpacing: '0.005em',
                                    textAlign: 'center',
                                    letterSpacing: '0.34px',
                                    color: '#000000DE',
                                    opacity: 1,
                                    marginLeft: '1vw',
                                    transform: 'translateY(50%)',
                                    alignSelf: 'center'
                              
                                }}>
                                    {email}
                                </Typography>
                                <IconButton
                                    size="medium"
                                    aria-label="logout"
                                    edge="end"
                                    color="inherit"
                                    style={{ justifyContent: 'flex-start', paddingLeft: '3vw', paddingRight: '2vw', transform: 'translateY(25%)', width:'100%', height:'100%' }}
                                    onClick={onLogout}
                                    disableRipple
                                >
                                    
                                    <LogoutComp size={'calc(10px + 2vh)'}/>
                                    
                                    <Typography variant="h5" style={{
                                        fontFamily: 'Source Sans Pro',
                                        fontSize: 'calc(10px + 1vh)',
                                        fontWeight: 600,
                                        lineHeight: '24px',
                                        letterSpacing: '0em',
                                        textAlign: 'center',
                                        letterSpacing: '0.34px',
                                        color: '#000000DE',
                                        opacity: 1
                                    }}>
                                        Logout
                                    </Typography>
                                </IconButton>
                            </div>
                        }
                    </StyledToolbar>
                </StyledAppBar>
            </ElevationScroll>
            <Toolbar />
        </React.Fragment>
    );
}
