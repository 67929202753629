import * as axios from 'axios';
import {_arrayBufferToBase64, log} from "./utils";

export default class Api {
    
    constructor() {
        this.api_url = process.env.REACT_API_ENDPOINT;
    }

    init = () => {
        let headers = {
            Accept: "application/json",
        };

        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 30000,
            headers: headers,
        });

        return this.client;
    };

    getQrCode = () => {
        const api = this.init();
        async function getQrCode(timestamp) {
            try {
                let response = await api.get("/ideepartners/login/qrcode", {
                    params: {
                        t: timestamp
                    }
                });
                if (response.data !== null ) {
                    log(response.data);
                    let base64Img = response.data.img;
                    let qrUrl = "data:image/png;base64," + base64Img;
                    log(qrUrl);
                    return Promise.resolve(qrUrl);
                }
            } catch (error) {
                log(error)
            }
        }
        return getQrCode(Date.now());
    };

    getQrCodeSetup = () => {
        const api = this.init();
        async function getQrCodeSetup(timestamp) {
            try {
                let response = await api.get("/ideepartners/login/qrcodeSetup", {
                    params: {
                        t: timestamp
                    }
                });
                if (response.data !== null ) {
                    log(response.data);
                    let base64Img = response.data.img;
                    let qrUrl = "data:image/png;base64," + base64Img;
                    log(qrUrl);
                    return Promise.resolve(qrUrl);
                }
            } catch (error) {
                log(error)
            }
        }
        return getQrCodeSetup(Date.now());
    };

    setupPrepare = () => {
        return this.init().post("/ideepartners/login/setupPrepare");
    };

    checkSessionLoggedIn = () => {
        const api = this.init();
        async function checkSession() {
            try {
                let response = await api.post("/ideesaml/login/check");
                if (response.data) {
                    return Promise.resolve(response.data);
                } else {
                    return Promise.reject("Response data is undefined");
                } 
            } catch (error) {
                return Promise.reject(error);
            }
        }

        return checkSession();
    }

    checkSession = () => {
        const api = this.init();
        async function check() {
            try {
                let response = await api.post("/ideepartners/login/check/session");
                if (response.data) {
                    return Promise.resolve(response.data);
                } else {
                    return Promise.reject("Response data is undefined");
                }
            } catch (error) {
                return Promise.reject(error);
            }
        }
        return check();
    };

    getContracts = (contracts) => {
        const api = this.init();
        async function getContracts() {
            try {
                let response = await api.get("/ideepartners/contracts/getcontracts");
                if (response.data !== null ) {
                    log(response.data);
                    contracts = response.data;
                    return response.data;
                }
            } catch (error) {
                log(error)
            }
        }
        return getContracts();
    }

    logout = () => {
        return this.init().post("/ideepartners/login/logout");
    };

}
