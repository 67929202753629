import MuiButton from "@mui/material/Button";
import {TableCell, styled} from "@mui/material";

const Button = styled(MuiButton)(({theme}) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 25,
    height: 30,
    padding: 14,
    cursor: 'pointer',
    marginBottom: 36,
    '&:hover': {
        backgroundColor: theme.palette.onHover.main,
        transform: 'scale(1.05)',
    },
    '&:disabled': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        opacity: 0.5
    }
}));

const ReviewButton = styled(MuiButton)(({theme}) => ({
    backgroundColor: theme.palette.primary.contrastText,
    color: "#FF5140",
    borderColor: "#FF5140",
    border: '2px solid',
    display: 'flex',
    padding: '11px 16px',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '4px',
    position: 'fixed',
    left: '20px',
    bottom: '20px',
    minWidth: 133,
    height: 48,
    borderRadius: 24,
    cursor: 'pointer',
    marginLeft: '1%'
}));

const InverseButton = styled(MuiButton)(({theme}) => ({
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    border: '1px solid',
    minWidth: 126,
    height: 32,
    borderRadius: 16,
    cursor: 'pointer',
    marginBottom: 38,
    '&:hover': {
        backgroundColor: theme.palette.onHover.inverse,
        transform: 'scale(1.05)',
    },
}));

const ButtonSignup  = styled(MuiButton)(({theme}) => ({
    backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderRadius: 18,
        minWidth: 195,
        height: 36,
        padding: 8,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.primary.main
        },
        textTransform: 'none'
}));

const InverseButtonSignup = styled(MuiButton)(({theme}) => ({
    backgroundColor: theme.palette.primary.contrastText,
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        border: '1px solid',
        borderRadius: 25,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.primary.contrastText
        },
}));

const HeaderTableCell = styled(TableCell)(({ theme }) => ({
    color: 'var(--Black, #000)',
    fontFamily: 'Source Sans Pro',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '16px',
    letterSpacing: '0.14px'
}));

const BodyTableCell = styled(TableCell)(({ theme }) => ({
    color: 'var(--Black, #000)',
    fontFamily: 'Source Sans Pro',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '0.09px'
}));

export {
    Button,
    InverseButton,
    ReviewButton,
    ButtonSignup,
    InverseButtonSignup,
    HeaderTableCell,
    BodyTableCell
};