import * as React from "react"



export default function SvgComponent  (fields) {
    const {size} = fields;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <mask id="path-1-inside-1_3105_2089" fill="white">
                <rect x="4" y="4" width="7" height="7" rx="1" />
            </mask>
            <rect x="4" y="4" width="7" height="7" rx="1" stroke="#293DC7" stroke-width="3" mask="url(#path-1-inside-1_3105_2089)" />
            <mask id="path-2-inside-2_3105_2089" fill="white">
                <rect x="4" y="13" width="7" height="7" rx="1" />
            </mask>
            <rect x="4" y="13" width="7" height="7" rx="1" stroke="#293DC7" stroke-width="3" mask="url(#path-2-inside-2_3105_2089)" />
            <mask id="path-3-inside-3_3105_2089" fill="white">
                <rect x="13" y="4" width="7" height="7" rx="1" />
            </mask>
            <rect x="13" y="4" width="7" height="7" rx="1" stroke="#293DC7" stroke-width="3" mask="url(#path-3-inside-3_3105_2089)" />
            <mask id="path-4-inside-4_3105_2089" fill="white">
                <rect x="13" y="13" width="7" height="7" rx="1" />
            </mask>
            <rect x="13" y="13" width="7" height="7" rx="1" stroke="#293DC7" stroke-width="3" mask="url(#path-4-inside-4_3105_2089)" />
        </svg>
    )
  } 


