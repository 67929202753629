import React, {createContext, useReducer} from "react";
import {tryLocalStorageGetItem, tryLocalStorageSetItem} from "./helpers/utils";


export const ACTION_TYPE = {
    PARTNER_USER: 'PARTNER_USER'
};

const initialState = {
    isPartnerUser: tryLocalStorageGetItem(ACTION_TYPE.PARTNER_USER, 'false') === 'true',
};

export const context = createContext(initialState);

export const { Provider } = context;

export const StateProvider = ({children}) => {
    const [state, dispatch] = useReducer((state, action) => {
        const currentState = {...state};
        switch (action.type) {
            case ACTION_TYPE.PARTNER_USER:
                tryLocalStorageSetItem(ACTION_TYPE.PARTNER_USER, JSON.stringify(action.payload));
                currentState.isPartnerUser = action.payload;
                return currentState;
            default:
                throw new Error();

        }}, initialState);
    return <Provider value={{state, dispatch}}>{children}</Provider>
};
