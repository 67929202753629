import React, { useEffect } from 'react';
import MuiToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from "@mui/material/Typography/Typography";
import {styled} from "@mui/material";
import MuiPaper from '@mui/material/Paper';
import {withRouter, useLocation} from "react-router-dom";

let borderRadius = 70;

const Paper = styled(MuiPaper)(({theme}) => ({
    borderRadius: borderRadius,
    display: 'inline-grid',
    minWidth :'3vw',
    width: '15vw',
    minHeight: '22vh'
}));

const ToggleButton = styled(MuiToggleButton)(({theme}) => ({
    border: 'none',
    display: 'block',
    borderRadius: borderRadius,
    minHeight: '50%',
    minWidth: '50%',
    '&:hover ,&.Mui-selected:hover' : {
        backgroundColor: '#F0F5FF',
    },
    '&:not(hover)' : {
        fill: '#808181'
    },
    '&.Mui-selected' : {
        backgroundColor: '#F0F5FF',
        fill: '#293dc7'
    },
}));

const VerticalMenu = (props) => {

    const { history, items} = props;
    let location = useLocation();
    const [selected, setSelected] = React.useState(location.pathname);

    useEffect(
        () => {
            if (selected !== location.pathname) {
                setSelected(location.pathname)
            }
        },
        [location.pathname]
    );

    const handleChange = (event, path) => {
        if (path !== null) {
            setSelected(path);
            history.push(path);
        }
    };

    return (
        <Paper  elevation={7} style={{boxShadow: 'none', width: '10dvw', maxWidth: '250px', minWidth: '150px'}}>
            <ToggleButtonGroup
                orientation="vertical"
                value={selected}
                exclusive
                onChange={handleChange}
            >
                {items.map((item, index) => {
                    const { title, path, icon } = item;
                    return (
                        <ToggleButton disableRipple fullWidth={true} value={path} key={index} aria-label={title} color="primary" sx={{minHeight: 'auto', justifyContent: 'center'}}>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <span style={{marginTop: '5px'}}> {icon} </span>
                                <Typography variant="h4_menu" sx={{marginLeft: '2px'}}>{title}</Typography>
                            </div>
                        </ToggleButton>
                    );
                })}
            </ToggleButtonGroup>
        </Paper>
    );
};

export default withRouter(VerticalMenu)