import {
    Table,
    TableBody,
    TableContainer,
    TableHead,
} from "@mui/material";
import React from "react";
import CollapsableRow from "./CollapsableRow.jsx";
import { 
    HeaderTableCell
 } from "./CustomUI";
 import {useTranslation} from "react-i18next";


export default function ContractTable(props) {
    const { rows } = props;
    const {t} = useTranslation();
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <HeaderTableCell></HeaderTableCell>
                    <HeaderTableCell> {t('contract_table.admin_header')} </HeaderTableCell>
                    <HeaderTableCell> {t('contract_table.contract_id_header')}</HeaderTableCell>
                    <HeaderTableCell> {t('contract_table.start_date_header')} </HeaderTableCell>
                    <HeaderTableCell> {t('contract_table.end_date_header')} </HeaderTableCell>
                    <HeaderTableCell> {t('contract_table.license_type_header')} </HeaderTableCell>
                    <HeaderTableCell> {t('contract_table.licensed_users_header')} </HeaderTableCell>
                    <HeaderTableCell> {t('contract_table.enrolled_users_header')} </HeaderTableCell>
                    <HeaderTableCell> {t('contract_table.integrations_header')} </HeaderTableCell>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => (
                        <CollapsableRow row={row} index={index} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}