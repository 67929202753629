import React, { useEffect } from 'react';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { getTheme } from "./theme/theme";
import { context, ACTION_TYPE } from "./State";
import { Switch } from "react-router-dom";
import ConfigManager from "./config/config"
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom"
import AuthRoute from "./AuthRoute";
import LoginPage from "./pages/login/LoginPage";
import Api from './helpers/service';
import { log, tryLocalStorageSetItem } from './helpers/utils'
import MainLayout from "./pages/main/MainLayout";
import SetupPage from "./pages/registration/SetupPage"


export const configManager = new ConfigManager().getInstance();

export const publicUrl = process.env.PUBLIC_URL;

export default function App() {

    const { t } = useTranslation();
    const location = useLocation();
    const { state, dispatch } = React.useContext(context);
    const theme = createTheme(getTheme());
    const [email, setEmail] = React.useState("");
    const api = new Api();
    let refreshSessionQrIntervalId;
    let refreshSessionQrInterval = 2; // in seconds
    

    useEffect(
        () => {
            checkSessionQr();
            refreshSessionQrIntervalId = setInterval(() => checkSessionQr(), refreshSessionQrInterval * 1000);
        },
        []
    );

    useEffect(() => () => clearInterval(refreshSessionQrIntervalId), []); // on unmount

    function checkSessionQr() {
        api.checkSession().then(function (res) {
            log(res);
            if (!!res.accessDenied) {
                clearInterval(refreshSessionQrIntervalId);
            } else if (!!res.sessionActive) {
                log("Logged in user:  " + res.email);
                setEmail(res.email);
                tryLocalStorageSetItem("email", res.email);

                if (!state.isPartnerUser) {
                    dispatch({ type: ACTION_TYPE.PARTNER_USER, payload: true });
                    window.location.reload(false);
                }

            }
        }).catch(function (error) {
            if (state.isPartnerUser) {
                dispatch({ type: ACTION_TYPE.PARTNER_USER, payload: false });
                window.location.reload(false);
            }
        })
    }

    return (
        <ThemeProvider theme={theme}>
            <Switch>
                <AuthRoute path="/login" type="guest" isPartnerUser={state.isPartnerUser}>
                    <LoginPage />
                </AuthRoute>
                <AuthRoute path="/setup" type="guest" isPartnerUser={state.isPartnerUser}>
                    <SetupPage />
                </AuthRoute>
                <AuthRoute path="/" type="private" isPartnerUser={state.isPartnerUser}>
                    <MainLayout email={email} />
                </AuthRoute>
            </Switch>
        </ThemeProvider>
    );
}