import {
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import { makeStyles } from "@mui/styles"
import React from "react";
import aws from "../img/aws.svg"
import google from "../img/google.svg"
import microsoft from "../img/office.svg"
import okta from "../img/okta.svg"
import oidc from "../img/openId.png"
import salesforce from "../img/salesforce.svg"
import teamviewer from "../img/teamviewer-icon.svg"
import zendesk from "../img/zendesk.svg"
import logoPlaceholder from "../img/logo-placeholder.svg"
import custom from "../img/custom.svg"
import { 
    BodyTableCell,
    HeaderTableCell
 } from "./CustomUI";
 import {useTranslation} from "react-i18next";

export default function IntegrationTable(props) {

    const { contractIndex, rows } = props;
    const {t} = useTranslation();   

    function getIntegrationLogo(integrationtype) {
        switch (integrationtype) {
            case "OKTA":
                return okta;
            case "GSUITE":
                return google
            case "SALESFORCE":
                return salesforce
            case "MICROSOFT":
                return microsoft
            case "AWS":
                return aws
            case "ZENDESK":
                return zendesk
            case "TEAMVIEWER":
                return teamviewer
            case "OIDC":
                return oidc
            case "CUSTOM":
                return custom
            default:
                return logoPlaceholder
        }

    }

    function getIntegrationType(integrationtype) {
        switch (integrationtype) {
            case "OKTA":
                return "Okta";
            case "GSUITE":
                return "Google Workspace"
            case "SALESFORCE":
                return "Salesforce"
            case "MICROSOFT":
                return "Microsoft 365"
            case "AWS":
                return "AWS"
            case "ZENDESK":
                return "Zendesk"
            case "TEAMVIEWER":
                return "Teamviewer"
            case "OIDC":
                return "Oidc"
            case "CUSTOM":
                return "Custom"
            default:
                return ""
        }

    }


    return (
        <TableContainer>
            <Table>
                <TableHead sx={{backgroundColor: '#F7F7F7'}}>
                    <HeaderTableCell>{t('integration_table.integration_type_id')}</HeaderTableCell>
                    <HeaderTableCell></HeaderTableCell>
                    <HeaderTableCell> {t('integration_table.integration_type_header')} </HeaderTableCell>
                    <HeaderTableCell> {t('integration_table.integration_name_header')} </HeaderTableCell>
                    <HeaderTableCell> {t('integration_table.domain_header')} </HeaderTableCell>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => (
                        <TableRow sx={{
                            color: 'var(--Black, #000)',
                            fontFamily: 'Source Sans Pro',
                            fontSize: '18px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '24px',
                            letterSpacing: '0.09px'
                        }}>
                            <BodyTableCell> {row.integrationId} </BodyTableCell>
                            <BodyTableCell padding={'none'} size={'small'} align={'left'}> {<img style={{ width: '3.5dvh' }} src={getIntegrationLogo(row.integrationType)}></img>} </BodyTableCell>
                            <BodyTableCell> {getIntegrationType(row.integrationType)} </BodyTableCell>
                            <BodyTableCell> {row.integrationName} </BodyTableCell>
                            <BodyTableCell> {row.domain} </BodyTableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}