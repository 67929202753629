
export default function ConfigManager() {

    let instance;
    

    function init() {

        

        if (process.env.REACT_APP_ENV === 'DEBUG' || process.env.NODE_ENV === 'development') {
            
        }

        return {

        };

    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = init();
            }
            return instance;
        }
    };

};