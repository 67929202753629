import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography/Typography";
import PropTypes from 'prop-types';
import { makeStyles } from "@mui/styles"
import { useTranslation } from "react-i18next";

var activeTab = 0;

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 2 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    tabRoot: {
        fontWeight: 600,
        borderRadius: "16px",
        borderBottomLeftRadius: "0px",
        borderBottomRightRadius: "0px",
        fontSize: "17px",
        "&:hover": {
            background: "#F0F5FF",
            color: "#293DC7",
            opacity: 1
        }
    }
}));

export default function BasicTabs(props) {
    const {mspContent, resellersContent} = props
    const [value, setValue] = React.useState(activeTab);
    const { t } = useTranslation();
    const classes = useStyles();

    const handleChange = (event, newValue) => {
        setValue(newValue);
        activeTab = newValue;
    };


    return (
        <Box sx={{ marginTop: '2%', width: '100%' }}>
            <Box sx={{ borderBottom: 'none' }}>
                <Tabs forceRenderTabPanel={true} onChange={handleChange} >
                    <Tab label={t('tabs.msp_heading')} classes={{ root: classes.tabRoot }} style={{
                        backgroundColor: value == 0 ? "#F0F5FF" : "inherit",
                        color: value == 0 ? "#293DC7" : "inherit",
                        borderRadius: "16px",
                        borderBottomLeftRadius: "0px",
                        borderBottomRightRadius: "0px"
                    }} />
                    <Tab label={t('tabs.resellers_heading')} classes={{ root: classes.tabRoot }} style={{
                        backgroundColor: value == 1 ? "#F0F5FF" : "inherit",
                        color: value == 1 ? "#293DC7" : "inherit",
                        borderRadius: "16px",
                        borderBottomLeftRadius: "0px",
                        borderBottomRightRadius: "0px"
                    }} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0} style={{ borderRadius: '16px', border: '1px solid #D7D7D7', padding: '16px 0px 16px 0px', marginLeft: '-15px' }}>
                {mspContent}
            </TabPanel>
            <TabPanel value={value} index={1} style={{ borderRadius: '16px', border: '1px solid #D7D7D7', padding: '16px 0px 16px 0px', marginLeft: '-15px' }}>
                {resellersContent}
            </TabPanel>
        </Box>
    );
}